import { patchState, signalStore, type, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import {  UserInformation } from '@twist/backbone-api-services/ciam';
import { setError, setLoaded, setLoading, withCallState } from '@twist/core';
import { inject } from '@angular/core';
import { UserManagementService } from '../services/user-management.service';

export const UserManagementStore = signalStore(
  withCallState(),
  withEntities({ entity: type<UserInformation>(), collection: 'users' }),
  withComputed(state => {
    return {};
  }),
  withMethods(state => {
    const usersService = inject(UserManagementService);
    const initialize = async () => {
      patchState(state, setLoading());
      try {
        const allUsers = await usersService.getUsers();
        patchState(state, setLoaded());
        if (allUsers?.results) {
          patchState(state, setAllEntities(allUsers?.results, {
            collection: 'users',
            selectId: x => x.userId
          }));
        }
      } catch (error) {
        patchState(state, setError('Failed to load users'));
      }
    };
    return {
      initialize
    };
  }),
  withHooks({
    onInit(state) {
      state.initialize();
    }
  })
);
