import { inject, Injectable } from '@angular/core';
import { ApiServiceBase } from '@twist/core';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  GetUsersQueryResult,
  UserManagementBackboneService
} from '@twist/backbone-api-services/ciam';

@Injectable({ providedIn: 'root' })
export class UserManagementService extends ApiServiceBase{
  private userManagementBackboneService = inject(UserManagementBackboneService);

  getUsers = (): Promise<GetUsersQueryResult> => {
    return new Promise<GetUsersQueryResult>((resolve, reject) => {
      firstValueFrom(
        this.userManagementBackboneService.getUsers()
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };
}
