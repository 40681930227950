import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { setLoaded, setLoading, withCallState } from '@twist/core';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementService } from '../services/user-management.service';
import { DtoUser } from '@twist/backbone-api-services/ciam';
import { CONTEXT_ORGANIZATION_KEY } from '../../index';

export interface UserStoreState {
  userKey: string | undefined;
  user: DtoUser | undefined;
}

const initialState: UserStoreState = {
  userKey: undefined,
  user: undefined
};

export const UserStore = signalStore(
  withState<UserStoreState>(initialState),
  withCallState(),
  withComputed(state => {
      return {};
    }
  ),
  withMethods(state => {

    const _router = inject(Router);
    const _usersService = inject(UserManagementService);

    const userKey = inject(CONTEXT_ORGANIZATION_KEY);

    const initialize = async () => {
/*      console.log('[UserStore] - Initializing');
      patchState(state, setLoading());

      if (!userKey) {
        await _router.navigateByUrl('/errors/404', { skipLocationChange: true });
        return;
      }

      _usersService.getUser(userKey)
        .then(result => {
          patchState(state, {
            userKey,
            user: result.user,
            ...setLoaded()
          });
        });*/
    };

    return { initialize };
  }),
  withHooks({
    onInit: store => {
      store.initialize();
    }
  })
);
