import { inject, Injectable } from '@angular/core';
import { ApiServiceBase } from '@twist/core';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  GetOrganizationQueryResult,
  GetOrganizationsQueryResult,
  OrganizationManagementBackboneService
} from '@twist/backbone-api-services/ciam';

@Injectable({ providedIn: 'root' })
export class OrganizationManagementService extends ApiServiceBase{
  private organizationManagementBackboneService = inject(OrganizationManagementBackboneService);

  getOrganization = (organizationKey: string): Promise<GetOrganizationQueryResult> => {
    return new Promise<GetOrganizationQueryResult>((resolve, reject) => {
      firstValueFrom(
        this.organizationManagementBackboneService.getOrganization(organizationKey)
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  getOrganizations = (): Promise<GetOrganizationsQueryResult> => {
    return new Promise<GetOrganizationsQueryResult>((resolve, reject) => {
      firstValueFrom(
        this.organizationManagementBackboneService.getOrganizations()
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };
}
