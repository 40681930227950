import { patchState, signalStore, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { setLoaded, setLoading, withCallState } from '@twist/core';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  AddOrganizationMemberCommandResult,
  DeleteOrganizationMemberCommandResult,
  Organization,
  OrganizationMember,
  OrganizationMemberInvitation,
  OrganizationMemberRole,
  UpdateOrganizationMemberCommandResult
} from '@twist/backbone-api-services/ciam';
import { removeEntity, setAllEntities, setEntity, withEntities } from '@ngrx/signals/entities';
import { CONTEXT_ORGANIZATION_KEY } from '../../index';
import { OrganizationMembersService } from '../services/organization-members.service';

export interface OrganizationMembersStoreState {
  organizationKey: string | undefined;
  organization: Organization | undefined;
}

const initialState: OrganizationMembersStoreState = {
  organizationKey: undefined,
  organization: undefined
};

export const OrganizationMembersStore = signalStore(
  withState<OrganizationMembersStoreState>(initialState),
  withEntities({ entity: type<OrganizationMember>(), collection: 'members' }),
  withEntities({ entity: type<OrganizationMemberInvitation>(), collection: 'invitations' }),
  withEntities({ entity: type<OrganizationMemberRole>(), collection: 'roles' }),
  withCallState(),
  withComputed(state => {
      return {};
    }
  ),
  withMethods(state => {
    const organizationKey = inject(CONTEXT_ORGANIZATION_KEY);
    const memberManagementService = inject(OrganizationMembersService);
    const _router = inject(Router);

    const initialize = async () => {
      console.log('[OrganizationMembersStore] - Initializing');
      patchState(state, setLoading());

      if (!organizationKey) {
        await _router.navigateByUrl('/errors/404', { skipLocationChange: true });
        return;
      }

      const getMembers = memberManagementService.getMembers(organizationKey);
      const getMemberRoles = memberManagementService.getMemberRoles(organizationKey);

      Promise.all([getMembers, getMemberRoles]).then((values) => {
        patchState(state, setAllEntities(values[0].results, { collection: 'members', selectId: x => x.key }));
        patchState(state, setAllEntities(values[1].results, { collection: 'roles', selectId: x => x.key }));
        patchState(state, setLoaded());
      });

    };

    const addMember = async (userId: string, roleKey: string): Promise<AddOrganizationMemberCommandResult> => {
      const commandResult = await memberManagementService.addOrganizationMember(
        organizationKey,
        {
          userId,
          roleKey
        });

      patchState(state, setEntity(commandResult.result, { collection: 'members', selectId: x => x.key }));

      return commandResult;
    };

    const updateMember = async (memberKey: string, roleKey: string): Promise<UpdateOrganizationMemberCommandResult> => {
      const commandResult = await memberManagementService.updateOrganizationMember(
        organizationKey,
        memberKey,
        {
          roleKey
        });

      patchState(state, setEntity(commandResult.result, { collection: 'members', selectId: x => x.key }));

      return commandResult;
    };

    const deleteMember = async (memberKey: string): Promise<DeleteOrganizationMemberCommandResult> => {
      const commandResult = await memberManagementService.deleteOrganizationMember(
        organizationKey,
        memberKey);

      patchState(state, removeEntity(memberKey, { collection: 'members' }));

      return commandResult;
    };

    return {
      initialize,
      addMember,
      updateMember,
      deleteMember
    };
  }),
  withHooks({
    onInit: store => {
      store.initialize();
    }
  })
);
