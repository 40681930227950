import { patchState, signalStore, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { setLoaded, setLoading, withCallState } from '@twist/core';
import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  CreateOrganizationApiKeyCommandResult,
  DeleteOrganizationApiKeyCommandResult,
  Organization,
  OrganizationApiKey,
  OrganizationPermissions,
  RegenerateOrganizationApiKeyCommandResult,
  RevokeOrganizationApiKeyCommandResult,
  UpdateOrganizationApiKeyCommandResult
} from '@twist/backbone-api-services/ciam';
import { removeEntity, setAllEntities, setEntity, withEntities } from '@ngrx/signals/entities';
import { CONTEXT_ORGANIZATION_KEY } from '../../index';
import { OrganizationApiKeysService } from '../services/organization-api-keys.service';
import { ModalService } from '@twist/ui/components';

export interface OrganizationApiKeysStoreState {
  organizationKey: string | undefined;
  organization: Organization | undefined;
}

const initialState: OrganizationApiKeysStoreState = {
  organizationKey: undefined,
  organization: undefined
};

export type ExtendedOrganizationApiKey = OrganizationApiKey & {
  isRevoked: boolean;
  isBlocked: boolean;
  isExpired: boolean;
  isActive: boolean;
};

export const OrganizationApiKeysStore = signalStore(
  withState<OrganizationApiKeysStoreState>(initialState),
  withEntities({ entity: type<OrganizationApiKey>(), collection: 'apiKeys' }),
  withCallState(),
  withComputed(state => {

      const apiKeys = computed<ExtendedOrganizationApiKey[]>(() => {
        return state.apiKeysEntities().map(key => {
          const revoked = key.revokedOnUtc != null;
          const blocked = key.blockedOnUtc != null;
          const expired = key.expiresAt != null && new Date(key.expiresAt) < new Date();
          return {
            ...key,
            isRevoked: revoked,
            isBlocked: blocked,
            isExpired: expired,
            isActive: !revoked && !blocked && !expired
          };
        });
      });

      return {
        apiKeys
      };
    }
  ),
  withMethods(state => {
    const organizationKey = inject(CONTEXT_ORGANIZATION_KEY);
    const apiKeyManagementService = inject(OrganizationApiKeysService);
    const _router = inject(Router);
    const _modalService = inject(ModalService);


    const initialize = async () => {
      console.log('[OrganizationApiKeysStore] - Initializing');
      patchState(state, setLoading());

      if (!organizationKey) {
        await _router.navigateByUrl('/errors/404', { skipLocationChange: true });
        return;
      }

      apiKeyManagementService.getAPIKeys(organizationKey)
        .then(response => {
          patchState(state, setAllEntities(response.results, { collection: 'apiKeys', selectId: x => x.key }));
          patchState(state, setLoaded());
        });
    };

    const createApiKey = async (
      name: string,
      associatedUserId: string,
      permissions: OrganizationPermissions[]
    ): Promise<CreateOrganizationApiKeyCommandResult> => {
      const creationResult = await apiKeyManagementService.createOrganizationApiKey(organizationKey,
        {
          name,
          associatedUserId,
          permissions
        });

      patchState(state, setEntity(creationResult.apiKey, { collection: 'apiKeys', selectId: x => x.key }));

      return creationResult;
    };

    const updateApiKey = async (
      apiKeyId: string,
      name: string,
      permissions: OrganizationPermissions[]
    ): Promise<UpdateOrganizationApiKeyCommandResult> => {
      const result = await apiKeyManagementService.updateOrganizationApiKey(
        organizationKey,
        apiKeyId,
        {
          name,
          permissions
        });
      patchState(state, setEntity(result.apiKey, { collection: 'apiKeys', selectId: x => x.key }));
      return result;
    };

    const revokeApiKey = async (apiKeyId: string): Promise<RevokeOrganizationApiKeyCommandResult | undefined> => {
      _modalService.confirm('Are you sure you want to revoke this api key?<br />This action cannot be undone.')
        .then(async (confirmed) => {
          if (confirmed) {
            const result = await apiKeyManagementService.revokeOrganizationApiKey(organizationKey, apiKeyId);
            patchState(state, setEntity(result.apiKey, { collection: 'apiKeys', selectId: x => x.key }));
            return result;
          }
          return undefined;
        });
      return undefined;
    };

    const regenerateApiKey = async (apiKeyId: string): Promise<RegenerateOrganizationApiKeyCommandResult> => {

      /* _modalService.confirm('Are you sure you want to regenerate this api key?<br />This action cannot be undone.')
         .then(async (confirmed) => {
           if (confirmed) {
             const result = await apiKeyManagementService.regenerateOrganizationApiKey(organizationKey, apiKeyId);
             patchState(state, setEntity(result.apiKey, { collection: 'apiKeys', selectId: x => x.key }));
             return result;
           }
           return undefined;
         });
       return undefined;*/

      return new Promise<RegenerateOrganizationApiKeyCommandResult>((resolve, reject) => {
        _modalService.confirm('Are you sure you want to regenerate this api key?<br />This action cannot be undone.')
          .then(async (confirmed) => {
            if (confirmed) {
              apiKeyManagementService.regenerateOrganizationApiKey(organizationKey, apiKeyId)
                .then(result => {
                  patchState(state, setEntity(result.apiKey, { collection: 'apiKeys', selectId: x => x.key }));
                  resolve(result);
                });
            } else {
              reject();
            }
          });
      });
    };

    const deleteApiKey = async (apiKeyId: string): Promise<DeleteOrganizationApiKeyCommandResult | undefined> => {
      _modalService.confirm('Are you sure you want to remove this api key?<br />This action cannot be undone.')
        .then(async (confirmed) => {
          if (confirmed) {
            const result = await apiKeyManagementService.deleteOrganizationApiKey(organizationKey, apiKeyId);
            patchState(state, removeEntity(result.apiKeyId, { collection: 'apiKeys' }));
            return result;
          }
          return undefined;
        });
      return undefined;
    };

    return {
      initialize,
      createApiKey,
      updateApiKey,
      revokeApiKey,
      regenerateApiKey,
      deleteApiKey
    };
  }),
  withHooks({
    onInit: store => {
      store.initialize();
    }
  })
);
