import { inject, Injectable } from '@angular/core';
import { ApiServiceBase } from '@twist/core';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  CreateOrganizationApiKeyCommand,
  CreateOrganizationApiKeyCommandResult,
  DeleteOrganizationApiKeyCommandResult,
  GetOrganizationApiKeysQueryResult,
  OrganizationAPIKeysBackboneService,
  RegenerateOrganizationApiKeyCommandResult,
  RevokeOrganizationApiKeyCommandResult,
  UpdateOrganizationApiKeyCommand,
  UpdateOrganizationApiKeyCommandResult
} from '@twist/backbone-api-services/ciam';

@Injectable({ providedIn: 'root' })
export class OrganizationApiKeysService extends ApiServiceBase {
  private backboneService = inject(OrganizationAPIKeysBackboneService);

  getAPIKeys = (organizationKey: string): Promise<GetOrganizationApiKeysQueryResult> => {
    return new Promise<GetOrganizationApiKeysQueryResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.getOrganizationApiKeys(
          organizationKey
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  createOrganizationApiKey = (
    organizationKey: string,
    command: CreateOrganizationApiKeyCommand): Promise<CreateOrganizationApiKeyCommandResult> => {
    return new Promise<CreateOrganizationApiKeyCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.createOrganizationApiKey(
          organizationKey, command
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };


  updateOrganizationApiKey = (
    organizationKey: string,
    apiKeyId: string,
    command: UpdateOrganizationApiKeyCommand): Promise<UpdateOrganizationApiKeyCommandResult> => {
    return new Promise<UpdateOrganizationApiKeyCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.updateOrganizationApiKey(
          organizationKey, apiKeyId, command
        ))
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };


  revokeOrganizationApiKey = (
    organizationKey: string,
    apiKeyId: string): Promise<RevokeOrganizationApiKeyCommandResult> => {
    return new Promise<RevokeOrganizationApiKeyCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.revokeOrganizationApiKey(
          organizationKey, apiKeyId
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  regenerateOrganizationApiKey = (
    organizationKey: string,
    apiKeyId: string): Promise<RegenerateOrganizationApiKeyCommandResult> => {
    return new Promise<RegenerateOrganizationApiKeyCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.regenerateOrganizationApiKey(
          organizationKey, apiKeyId
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  deleteOrganizationApiKey = (
    organizationKey: string,
    apiKeyId: string): Promise<DeleteOrganizationApiKeyCommandResult> => {
    return new Promise<DeleteOrganizationApiKeyCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.deleteOrganizationApiKey(
          organizationKey, apiKeyId
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };
}
