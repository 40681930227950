import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { setLoaded, setLoading, withCallState } from '@twist/core';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationManagementService } from '../services/organization-management.service';
import { Organization } from '@twist/backbone-api-services/ciam';
import { CONTEXT_ORGANIZATION_KEY } from '../../index';

export interface OrganizationStoreState {
  organizationKey: string | undefined;
  organization: Organization | undefined;
}

const initialState: OrganizationStoreState = {
  organizationKey: undefined,
  organization: undefined
};

export const OrganizationStore = signalStore(
  withState<OrganizationStoreState>(initialState),
  withCallState(),
  withComputed(state => {
      return {};
    }
  ),
  withMethods(state => {

    const _router = inject(Router);
    const _organizationsService = inject(OrganizationManagementService);

    const organizationKey = inject(CONTEXT_ORGANIZATION_KEY);

    const initialize = async () => {
      console.log('[OrganizationStore] - Initializing');
      patchState(state, setLoading());

      if (!organizationKey) {
        await _router.navigateByUrl('/errors/404', { skipLocationChange: true });
        return;
      }

      _organizationsService.getOrganization(organizationKey)
        .then(result => {
          patchState(state, {
            organizationKey,
            organization: result.organization,
            ...setLoaded()
          });
        });
    };

    return { initialize };
  }),
  withHooks({
    onInit: store => {
      store.initialize();
    }
  })
);
