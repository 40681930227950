import { patchState, signalStore, type, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { Organization } from '@twist/backbone-api-services/ciam';
import { setError, setLoaded, setLoading, withCallState } from '@twist/core';
import { inject } from '@angular/core';
import { OrganizationManagementService } from '../services/organization-management.service';

export const OrganizationManagementStore = signalStore(
  withCallState(),
  withEntities({ entity: type<Organization>(), collection: 'organizations' }),
  withComputed(state => {
    return {};
  }),
  withMethods(state => {
    const organizationsService = inject(OrganizationManagementService);
    const initialize = async () => {
      patchState(state, setLoading());
      try {
        const allOrganizations = await organizationsService.getOrganizations();
        patchState(state, setLoaded());
        if (allOrganizations?.results) {
          patchState(state, setAllEntities(allOrganizations?.results, {
            collection: 'organizations',
            selectId: x => x.key
          }));
        }
      } catch (error) {
        patchState(state, setError('Failed to load organizations'));
      }
    };
    return {
      initialize
    };
  }),
  withHooks({
    onInit(state) {
      state.initialize();
    }
  })
);
