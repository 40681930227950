import { inject, Injectable } from '@angular/core';
import { ApiServiceBase } from '@twist/core';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AddOrganizationMemberCommand,
  AddOrganizationMemberCommandResult,
  DeleteOrganizationMemberCommandResult,
  GetOrganizationMemberRolesQueryResult,
  GetOrganizationMembersQueryResult,
  OrganizationMembersBackboneService,
  UpdateOrganizationMemberCommand,
  UpdateOrganizationMemberCommandResult
} from '@twist/backbone-api-services/ciam';

@Injectable({ providedIn: 'root' })
export class OrganizationMembersService extends ApiServiceBase {
  private backboneService = inject(OrganizationMembersBackboneService);

  getMembers = (organizationKey: string): Promise<GetOrganizationMembersQueryResult> => {
    return new Promise<GetOrganizationMembersQueryResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.getOrganizationMembers(
          organizationKey
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  getMemberRoles = (organizationKey: string): Promise<GetOrganizationMemberRolesQueryResult> => {
    return new Promise<GetOrganizationMemberRolesQueryResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.getOrganizationMemberRoles(
          organizationKey
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  addOrganizationMember = (
    organizationKey: string,
    command: AddOrganizationMemberCommand): Promise<AddOrganizationMemberCommandResult> => {
    return new Promise<AddOrganizationMemberCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.addOrganizationMember(
          organizationKey, command
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  updateOrganizationMember = (
    organizationKey: string,
    memberKey: string,
    command: UpdateOrganizationMemberCommand): Promise<UpdateOrganizationMemberCommandResult> => {
    return new Promise<UpdateOrganizationMemberCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.updateOrganizationMember(
          organizationKey, memberKey, command
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };

  deleteOrganizationMember = (
    organizationKey: string,
    memberKey: string): Promise<DeleteOrganizationMemberCommandResult> => {
    return new Promise<DeleteOrganizationMemberCommandResult>((resolve, reject) => {
      firstValueFrom(
        this.backboneService.deleteOrganizationMember(
          organizationKey, memberKey
        )
      )
        .then((result) => {
          resolve(result);
        })
        .catch(async (err: HttpErrorResponse | null) => {
          await this.handleErrorAndReject('', err, reject);
        });
    });
  };
}
